<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item>优惠套餐</el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>
    <div style="padding: 50px; padding-right: 10%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="活动标题：" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="活动规则：" prop="Monetary">
          <el-input v-model="ruleForm.Monetary"></el-input>
        </el-form-item>
        <el-form-item label="缩例图" prop="imageUrl">
          <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadImg">
            <img v-if="imageUrl" :src="'http://192.168.3.3' + imageUrl" class="avatar" />
            <el-button size="small" type="primary" class="uploadBtu">选择图片</el-button>
          </el-upload>
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="商品原价：" prop="originalPrice" style="width: 25%">
            <el-input v-model="ruleForm.originalPrice" placeholder="必填"></el-input>
          </el-form-item>
          <el-form-item label="套餐价格：" prop="PackagePrice" style="width: 25%">
            <el-input v-model="ruleForm.PackagePrice"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="活动库存：" prop="inventory" style="width: 25%">
            <el-input v-model="ruleForm.inventory" placeholder="必填"></el-input>
          </el-form-item>
          <el-form-item label="每人限购：" prop="limitation" style="width: 25%">
            <el-input v-model="ruleForm.limitation"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="活动清单：" prop="table">
          <el-table :data="ruleForm.tableData" border style="width: 100%; border: 1px solid #e5e5e5 !important">
            <el-table-column label="图片" align="center">
              <template slot-scope="scope">
                <img :src="'http://192.168.3.3' + scope.row.thumbnail" style="max-height: 150px" />
              </template>
            </el-table-column>
            <el-table-column prop="product_name" label="品名" align="center">
            </el-table-column>
            <el-table-column prop="specifications" label="规格" align="center">
            </el-table-column>
            <el-table-column prop="model" label="型号" align="center">
            </el-table-column>
            <el-table-column prop="sales_price" label="价格" align="center">
            </el-table-column>
            <el-table-column prop="number" label="数量" align="center">
              <template slot-scope="scope">
                <el-input v-model="ruleForm.inpArr[scope.$index]" @input="
                  inputChange(
                    scope.row,
                    scope.$index,
                    ruleForm.inpArr[scope.$index]
                  )
                  "></el-input>
              </template>
            </el-table-column>
            <el-table-column label=" " min-width="20" align="center">
              <template slot-scope="scope">
                <i class="el-icon-delete" @click="tableClickClose(scope.$index)"></i>
              </template>
            </el-table-column>
          </el-table>
          <el-button type="primary" style="margin-top: 15px" @click="dialogVisible = !dialogVisible">添加商品</el-button>
        </el-form-item>
        <el-form-item label="赠劵" prop="region">
          <el-select v-model="ruleForm.region" placeholder="--选择--">
            <el-option v-for="item in waterList" :key="item.id" :label="item.wt_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="ruleForm.status">
            <el-radio label="待发布"></el-radio>
            <el-radio label="进行中"></el-radio>
            <el-radio label="已关闭"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <AddProduct :productShow="dialogVisible" @addConfirm="addConfirm" @handleClose="handleClose" />
  </div>
</template>
<script>
import AddProduct from "../../../../addProduct/addProduct.vue";
import qs from "qs";
export default {
  props: ["breadcrumb", "data"],
  components: { AddProduct },
  data() {
    const imgRule = (rule, value, callback) => {
      console.log(value);
      if (value === "") {
        callback(new Error("请添加缩略图"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        name: "",
        Monetary: "",
        pic: "",
        originalPrice: "",
        PackagePrice: "",
        inventory: "",
        limitation: "",
        tableData: [],
        region: "",
        status: "",
        inpArr: [],
      },
      rules: {
        name: [{ required: true, message: "这是必填字段" }],
        Monetary: [{ required: true, message: "这是必填字段" }],
        imageUrl: [{ required: true, validator: imgRule }],
        originalPrice: [{ required: true, message: "这是必填字段" }],
        PackagePrice: [{ required: true, message: "这是必填字段" }],
        inventory: [{ required: true, message: "这是必填字段" }],
        limitation: [{ required: true, message: "这是必填字段" }],
        tableData: [{ required: true, message: "这是必填字段" }],
        status: [{ required: true, message: "这是必填字段" }],
      },
      imageUrl: "",
      id: 0,
      // 弹窗
      dialogVisible: false,
      isResult: false,
      waterList: [],
    };
  },
  mounted() {
    this.$http
      .post(
        "/water_ticket/lst",
        qs.stringify({ currentPage: 0, currentLength: 0 })
      )
      .then((res) => {
        this.waterList = res.data.data;
      });
    if (this.$props.data) {
      const data = this.$props.data;
      this.id = data.id;
      this.ruleForm.name = data.activity_title;
      this.ruleForm.Monetary = data.activity_rule;
      this.imageUrl = data.thumbnail;
      this.ruleForm.PackagePrice = data.package_price;
      this.ruleForm.inventory = data.activity_inventory;
      this.ruleForm.limitation = data.limited_purchase;
      this.ruleForm.region = data.coupon;
      this.ruleForm.status = data.status;
      this.ruleForm.originalPrice = data.original_price;
      if (Array.isArray(data.product_arr)) {
        data.product_arr.forEach((item) => {
          this.ruleForm.inpArr.push(item.amount);
        });
      }
      this.ruleForm.tableData = data.product_arr;
    }
  },
  computed: {},
  methods: {
    closeClick() {
      this.$emit("close", true);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/discount_package/save", {
              id: this.id,
              activity_title: this.ruleForm.name,
              activity_rule: this.ruleForm.Monetary,
              thumbnail: this.imageUrl,
              package_price: this.ruleForm.PackagePrice,
              activity_inventory: this.ruleForm.inventory,
              limited_purchase: this.ruleForm.limitation,
              coupon: this.ruleForm.region,
              activity_type: "优惠",
              status: this.ruleForm.status,
              original_price: this.ruleForm.originalPrice,
              product_arr: JSON.stringify(this.ruleForm.tableData),
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$emit("close", true);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    inputChange(row, index, value) {
      // this.ruleForm.tableData.forEach((item, index) => {
      //   if (item.id == row.id) {
      //     console.log(item);

      //   }
      // });
      this.ruleForm.tableData[index].amount = value;
      //   console.log(row, val);
      // console.log(this.ruleForm.tableData);
      console.log(this.ruleForm.tableData);
      // console.log(row, index, value);
    },
    async uploadImg(file) {
      try {
        const base64 = await this.getBase64(file.file);
        const image = await this.$http.post("uploads_images/up", {
          file: base64,
        });
        this.imageUrl = image.data.path;
      } catch (error) {
        console.log(error);
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    tableClickClose(i) {
      this.ruleForm.tableData.splice(i, 1);
      this.ruleForm.inpArr.splice(i, 1);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    addConfirm(val) {
      // this.$set(this.ruleForm.inpArr, this.ruleForm.tableData.length, 1)
      val.forEach((item, index) => {
        const num = this.ruleForm.tableData.length;
        this.ruleForm.inpArr.push(1);
        this.ruleForm.tableData.push(item);
        this.ruleForm.tableData[num].amount = 1;
      });
      console.log(this.ruleForm.tableData);
      this.dialogVisible = false;
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a {
  color: #333;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

.dialogSelect {
  height: 32px;
}

.file_form {
  display: flex;
  justify-content: space-between;

  .file {
    padding: 20px;

    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.tableBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.label {
  background-color: #ffeabf;
}

.table {
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  width: 50%;

  tr {
    th {
      border: 1px solid #e5e5e5;
    }

    td {
      border: 1px solid #e5e5e5;
      padding: 10px;
    }
  }
}
</style>

<style lang="less" scoped>
.el-input__inner {
  padding: 0 4px !important;
  color: #333 !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #333 !important;
}

.avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff !important;
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px !important;
}
</style>
